<template>
  <div class="step-container">
    <div class="text-center">
      <div style="margin-top:12px">

          <div class="pc">
            <div class="h6 main step-title">내 서비스가 개설되면 다양한 앱이 있는 앱 마켓도 이용 할 수 있어요.</div>
            <div class="step4-content-wrapper" style="margin-top:40px">
              <div style="width:350px;margin-right:92px">
                <div class="subtitle4 main">개설 후 팩과 플러그인을 추가할 수 있어요.</div>
                <div class="flex"
                     style="align-items:flex-start;margin-top:16px"
                     v-for="(st1_c, st1_c_idx) in contents"
                     :key="`st1_c-${st1_c_idx}`">
                  <img src="/static/icon/u_check.svg" class="svg-primary" style="width:22px;height:22px"/>
                  <div class="body2 sub2" style="margin-left:8px">{{ st1_c }}</div>
                </div>
              </div>
              <img src="/static/img/app_market.png" style="width:433px"/>
            </div>
          </div>
          <div class="mobile">
            <div class="text-center margin-bottom-32 main mo-section-title-bold">내 서비스가 개설되면<br>다양한 앱이 있는<br>앱 마켓도 이용 할 수 있어요.</div>
            <div style="padding:16px 20px 32px;background-color: #f5f5f5;border-radius: 8px;">
              <div class="flex"
                  style="align-items:flex-start;margin-top:16px"
                  v-for="(st1_c, st1_c_idx) in contents"
                  :key="`st1_c-${st1_c_idx}`">
                <img src="/static/icon/u_check.svg" class="svg-primary" style="width:24px;height:24px"/>
                <div class="body4 sub2" style="text-align:left;margin-left:8px">{{ st1_c }}</div>
              </div>
            </div>
          </div>
      </div>
      <div class="flex-center">
        <div class="flex-align" style="max-width:450px;width:100%;margin-bottom:40px">
          <button class="button is-primary body2-medium" @click="clickNext"
                  style="width:100%;height:52px;margin-top:40px;">다음
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "ServiceCreateStep5",
    components: {},
    props: {
      order: {
        type: Object
      }
    },
    data() {
      return {
        contents: [
          '앱 마켓에 있는 기능들도 14일간 무료!',
          '내 플랫폼 서비스에 원하는 기능을 자유롭게 붙여볼 수 있어요',
          '작게 시작하고! 플러그인으로 고도화 하고!'
        ],
      }
    },
    computed: {
    },
    methods: {
      clickNext() {
        this.$emit('next');
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  @media (min-width: 1025px)
    .v-mobile
      display none

    .plan-select-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(3, 1fr)
    .plan-item
      padding-bottom 12px

    .plan-item:first-child
      padding-top 0
      border-top 0
  @media (max-width: 1024px)
    .step-title
      white-space pre-line
    .step-container
      height 100%
      padding-top 40px
      padding-left 16px
      padding-right 16px

    .plan-select-wrapper
      margin-bottom 10px

    .plan-sale
      padding 12px 0
      text-align center
      border-top 1px solid $gray2
      border-bottom 1px solid $gray2
      margin 12px 0

      img
        vertical-align sub
    .plan-item
      border 0
      padding 10px 0


  .option-st
    border 1px solid $gray1
    border-radius 8px
    margin-bottom 20px


  .tag-primary
    color $primary
    background-color $primary-light2
    border-radius 4px
    font-size 13px
    padding 2px 8px

  .box-step
    max-width 450px
    margin 0 auto
    padding 32px
    background-color $gray4
    border-radius 8px
    text-align left

  .box-info
    margin-top 4px
    background-color $gray3
    width 100%
    padding 10px
    border-radius 8px
    display flex
    align-items center
    justify-content center

    img
      width 16px
      height 16px
      margin-right 4px


  .pc
    .option-st
      padding 20px 24px

    .option-st.selected
      padding 19px 23px

  .mobile
    .option-st
      padding 16px 20px

    .option-st.selected
      padding 15px 19px

  .disabled
    background-color #eee

  .selected
    border 2px solid $primary !important

  .step4-content-wrapper
    background-image url(/static/img/bg_info.svg)
    background-repeat no-repeat
    background-size cover
    background-position center
    height 381px
    padding 40px 50px
    text-align left
    display flex
    align-items center
    justify-content space-between

  .title-s-box
    padding-bottom 12px
    border-bottom 1px solid $gray2

  .hosting-box
    width 100%
    padding-top 60px
    border-top 1px solid $gray
</style>
